//custom configuration management

let prod = {
  baseURL: "https://api.gmpauditreports.com/",
  googleRecaptchaSiteKey: "6LfvNacpAAAAAJy4-Ckgrcj6vPGimfn5bm8llrIn",
  payment: {
    stripe: {
      publishableKey:
        "pk_test_51OyeSDSIUj2dG8SFgX6PoGK5VDRxDPr0bn1fW5txQ627XuwifgWumZOTjMAIa1O4hk2xXLZ7pi2FEr7M9M4JODGc00Qyo8l8o5",
    },
  },
};

//we can use dynamic imports with import(`${NODE_ENV}`) but is supported only in modern browsers
//and import from development.js and production.js

let dev = {
  baseURL: "https://api.gmpauditreports.com/",
  googleRecaptchaSiteKey: "6Lc-LjwnAAAAAD1o4ZIFwO4bKpOl295htE8mkzgK",
  payment: {
    stripe: {
      publishableKey:
        "pk_test_51NjkGYSEoD96V7gvueotkT8YlNEq4ciyKubzwboUWv6GufdUEobWgvUL4BFFrYFyogM1KPFikWzBaVtfXaaoQFU000VWCurmDB",
    },
  },
};

export default process.env.NODE_ENV === "development" ? dev : prod;
