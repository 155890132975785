import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../config";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adminLoginService } from "../../services/auth";
import { toast } from "react-toastify";
import { useAuth } from "../AuthContext";
import Header from "../Header";
function AdminLoginPage({ showBackground }) {
  const { setIsLoggedIn, setUserRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [role, setRole] = useState(location.state?.role || "admin");
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    recaptchaString: "asdfgdfghjm",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    adminLoginService(formData)
      .then(() => {
        setRole("admin");
        setIsLoggedIn(true);
        setUserRole("admin");
        toast.success("Login successful! Redirecting to profile...");
        navigate("/profile");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <Header showBackground={showBackground} />
      {/* Section: Design Block */}
      <section
        id="login"
        className="background-radial-gradient overflow-hidden"
      >
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: 10 }}>
              <h1
                className="my-5 display-5 fw-bold ls-tight"
                style={{ color: "rgb(255, 255, 255)" }}
              >
                Welcome back to the Admin Login platform <br />
                <span style={{ color: "rgb(255, 255, 255)" }}>
                  Enter and Explore
                </span>
              </h1>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div
                id="radius-shape-1"
                className="position-absolute rounded-circle shadow-5-strong"
              ></div>
              <div
                id="radius-shape-2"
                className="position-absolute shadow-5-strong"
              ></div>

              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="email">
                        Username
                      </label>
                      <input
                        type="text"
                        id="username"
                        className="form-control"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                      />
                    </div>

                    {/* <!-- Password input --> */}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-outline mb-4 captcha-container">
                      <ReCAPTCHA sitekey={config.googleRecaptchaSiteKey} />
                    </div>
                    {/* <!-- Submit button --> */}
                    <button
                      type="submit"
                      className="btn btn-danger btn-block mb-4"
                    >
                      Login
                    </button>
                    <div className="form-outline mb-4">
                      <Link className="text-primary" to="/forgot-password">
                        Forgot your Password?
                      </Link>
                    </div>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section: Design Block */}
    </>
  );
}

export default AdminLoginPage;
