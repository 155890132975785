import React, { useState } from "react";
import { useEffect } from "react";
import { sendInquiry } from "../../services/plan";
import { toast } from "react-toastify";
import { downloadFileService } from "../../services/fileutility";

const TableWithPagination = (props) => {
  const { data } = props;
  const itemsPerPage = 6; // Number of items to display per page

  const [itemIds, setItemIds] = useState([]);

  const [tableData, setTableData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setTableData(data);
    setCurrentPage(1);
  }, [data]);

  useEffect(() => {
    if (showDialog) {
      // document.body.classList.add("blur");
    } else {
      // Remove blur effect when dialog is hidden
      document.body.classList.remove("blur");
    }
  }, [showDialog]);

  // Calculate total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleCheckboxChange = (itemid) => {
    if (itemIds.includes(itemid)) {
      setItemIds(itemIds.filter((selected) => selected !== itemid));
    } else {
      setItemIds([...itemIds, itemid]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowDialog(true);
    // --continue from here
  };

  const handleCloseDialog = () => {
    // setMessage("");
    setShowDialog(false);
  };

  const handleSendEnquiry = () => {
    setShowDialog(false);
    const data = { message: message, itemIds: itemIds };
    const sendInquiryPromise = sendInquiry(data)
      .then(() => {
        setItemIds([]);
        setMessage("");
      })
      .catch((error) => {
        console.error("Error details:", error); // This will log the error object
        return Promise.reject(error); // Forward the error for toast handling
      });
    toast.promise(sendInquiryPromise, {
      pending: "Your request is in progress",
      success:
        "Your inquiry was successfully submitted. \n An email was sent to you and the audit service providers.",
      error: {
        render({ data }) {
          // `data` will be the error object
          return `Inquiries sending failed: ${data || "Unknown error"}`;
        },
      },
    });
  };

  const downloadReport = (personId) => {
    // Call your downloadCurrentUserFileService function to get the promise
    downloadFileService("report", personId)
      .then((response) => {
        // Check if the response contains a valid URL
        if (response.status && response.data) {
          const downloadUrl = response.data;
          // Open the download URL in a new tab or window
          window.open(downloadUrl, "_blank");
        } else {
          console.error("Invalid response format:", response);
          toast.error(response);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <section id="tablewithpagination" className="container-fluid">
        <div className="p-3 0 my-3 mx-2">
          <table
            id="table"
            className="table table-responsive table-hover caption-top"
          >
            {/* <caption>List of items</caption> */}
            <thead>
              <tr>
                <th scope="col" className="col-1 text-center">
                  S.No
                </th>
                <th scope="col" className="col-1 text-center">
                  Product Name
                </th>
                <th scope="col" className="col-1 text-center">
                  Audit Service Provider Name
                </th>
                <th scope="col" className="col-1 text-center">
                  Company Brief
                </th>
                <th scope="col" className="col-1 text-center">
                  Date of Audit
                </th>
                <th scope="col" className="col-1 text-center">
                  Report Valid Till
                </th>
                <th scope="col" className="col-1 text-center">
                  Sample Report
                </th>
                <th scope="col" className="col-1 text-center">
                  Select Box
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index + 1}>
                  <td scope="row" className="col-1 text-center">
                    {index + 1}
                  </td>
                  <td className="col-1 text-center">{item.productName}</td>
                  <td className="col-1 text-center">
                    {item.Vendor.Person.company}
                  </td>
                  <td className="col-1 text-center">
                    <span
                      className="truncated-text"
                      title={item.Vendor.companyBrief}
                    >
                      {item.Vendor.companyBrief.slice(0, 10)}...
                    </span>
                  </td>

                  <td className="col-1 text-center">
                    {new Date(item.dateOfAudit).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="col-1 text-center">
                    {new Date(item.reportValidTill).toLocaleDateString(
                      "en-GB",
                      {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      }
                    )}
                  </td>
                  <td className="col-1 text-center">
                    <button
                      className="btn btn-danger" // Apply Bootstrap success button styling
                      onClick={() => downloadReport(item.vendorId)} // Call a function to initiate the download
                    >
                      <i className="bi bi-download"></i>{" "}
                    </button>
                  </td>
                  <td className="col-1 text-center">
                    <input
                      type="checkbox"
                      checked={itemIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-12 text-center">
              <button
                onClick={(e) => handleSubmit(e)}
                className="btn btn-danger"
                type="submit"
                disabled={itemIds.length === 0}
              >
                {itemIds.length === 1 ? "Send Inquiry" : "Send Inquiries"}
              </button>
            </div>
          </div>
        </div>
        <nav
          aria-label="Page navigation"
          style={{
            marginTop: "auto", // Push the pagination to the bottom
          }}
        >
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNum) => (
                <li
                  key={pageNum}
                  className={`page-item ${
                    pageNum === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNum)}
                  >
                    {pageNum}
                  </button>
                </li>
              )
            )}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
        {showDialog && (
          <div
            className="modal blur"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-scrollable modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Enter Your Message</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseDialog}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Send your inquiry..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleSendEnquiry}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default TableWithPagination;
