import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const ApexBarMarkerChart = (props) => {
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: ["#00E396"],
    dataLabels: {
      formatter: function (val, opt) {
        const goals =
          opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
            .goals;

        if (goals && goals.length) {
          return `${val} / ${goals[0].value}`;
        }
        return val;
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      // customLegendItems: ["Actual", "Expected"],
      // markers: {
      //   fillColors: ["#00E396", "#775DD0"],
      // },
    },
  });

  const [series, setSeries] = useState([]);

  useEffect(() => {
    const transformedData = props.countOfinquiriesMadeData.map((item) => ({
      x: item.month,
      y: item.count,
    }));
    setSeries([
      {
        name: "Stats By Month",
        data: transformedData,
      },
    ]);
  }, [props.countOfinquiriesMadeData]);

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default ApexBarMarkerChart;
