import React from "react";
import QAndA from "../subcomponents/QAndA";

function MainFAQSection() {
  const qAndAs = [
    {
      question: "How It Work for Audit Report Buyer?",
      answer: [
        {
          type: "p",
          content:
            "Our unique platform will reduce your audit report burden and saves you time.",
        },
      ],
      dataAos: "faq-list-1",
    },
    {
      question: "Buy An Existing Audit Report?",
      answer: [
        { type: "step", content: "Step-1: Register and submit your request" },
        "Register your details with our platform and get access to the best audit service providers across the globe.",
        "Submit your audit report request with the identified audit service providers.",
        { type: "step", content: "Step-2: Receive the quote and finalise:" },
        "Receive the best cost-effective quotes as per your audit report requirement.",
        "Finalise the quote and get the audit report package directly from the audit service provider.",
      ],
      dataAos: "faq-list-2",
      dataAosDelay: "100",
    },
    {
      question: "How It Work for Audit Service Provider?",
      answer: [
        { type: "step", content: "Step-1: Register your company" },
        "Register your company details and audit reports available with you.",
        "Get instant access to the details of audit report buyers.",
        { type: "step", content: "Step-2: Receive the inquiries" },
        "Get inquiries and submit the best quote directly to the audit report buyers.",
      ],
      dataAos: "faq-list-3",
      dataAosDelay: "100",
    },
  ];

  return (
    <>
      <section id="faq" className="faq section-bg">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </div>

          <div className="faq-list">
            <ul>
              {qAndAs.map((qAndA, index) => (
                <QAndA
                  key={index}
                  question={qAndA.question}
                  answer={qAndA.answer}
                  dataAos={qAndA.dataAos}
                  dataAosDelay={qAndA.dataAosDelay}
                />
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default MainFAQSection;
