const aboutBulletsData = [
  {
    iconClass: "bx-fingerprint",
    title: "For Report Buyers",
    description:
    "is established to ease the buying and selling of existing audit reports. Our user-friendly platform simplifies the management of supplier qualification audit reports and saves time and cost to the manufacturers. ",
    link: "",
  },
  {
    iconClass: "bx-gift",
    title: "For Audit Service Providers",
    description:
    "connect the audit report buyers, sellers and auditors to effectively manage the supplier qualification audits. We aim to help the global healthcare and pharma industries maintain high-quality and safety standards in their supply chains.",
    link: "",
  },
];

export default aboutBulletsData;
