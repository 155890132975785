import React from "react";
import Header from "../Header";
import ContactBanner from "../subcomponents/ContactBanner";
import MainContactSection from "../homepage-section-components/MainContactSection";

function ContactPage({ showBackground }) {
  return (
    <>
      <Header showBackground={showBackground} />
      <ContactBanner />
      <MainContactSection />
    </>
  );
}

export default ContactPage;
