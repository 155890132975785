import React from "react";

function ProfileSubscribedPlans({ credits }) {
  return (
    <div className="" id="subscribed-plans">
      <table className="table ">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Plan</th>
            <th scope="col">Remaining Credits</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">1</th>
            <td>All Plans Together</td>
            <td>{credits}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ProfileSubscribedPlans;
