import React from "react";
import Header from "../Header";
import AboutUsBanner from "../subcomponents/AboutUsBanner";
import MainAboutSection from "../homepage-section-components/MainAboutSection";

function AboutUsPage({ showBackground }) {
  return (
    <>
      <Header showBackground={showBackground} />
      <AboutUsBanner />
      <MainAboutSection />
    </>
  );
}

export default AboutUsPage;
