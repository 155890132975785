import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../AuthContext";
import { isAuthenticated } from "../../utils";

const BuyReportingIntro = () => {
  const sectionStyle = {
    position: "relative",
    height: "auto",
  };

  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
    backgroundImage: `abcdurl(${process.env.PUBLIC_URL}/assets/gif/librarygif1.gifsdf)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: 1, // Adjust the opacity as needed
    // backgroundColor: "rgb(27 204 141 / 7%)",
  };

  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  return (
    <section
      className="buyreportingintro position-relative"
      id="buyreportingintro"
      style={sectionStyle}
    >
      {/* Background GIF */}
      <div
        className="mbr-background-video isMuted"
        style={backgroundStyle}
      ></div>
      {/* <div
       className="mbr-overlay"
        style={{ opacity: "0.4", backgroundColor: "rgb(0, 0, 0)" }}
      ></div> */}
      <div className="align-center mx-5 py-5 section-custom">
        <div className="row justify-content-center text-align-center">
          <div className="col-md-3" data-aos="fade-right">
            <img
              src={"assets/img/tab/Tab-1 image.jpg"}
              className="img-fluid rounded"
              alt=""
            />
          </div>
          <div
            className="col-md-6 content d-flex justify-content-center flex-direction-column"
            data-aos="fade-left"
          >
            <h3 className="align-left  pb-3 ">Buy An Existing Audit Report</h3>
            <p className="mbr-text align-left pb-4 ">
              Planning to purchase an audit report from the best audit service
              providers?
            </p>
            <div className="mbr-section-btn align-center d-flex flex-wrap  justify-content-md-space-around justify-content-sm-space-around">
              <button
                className="btn btn-md btn-danger rounded-pill px-4 py-2 display-4 mr-2 mb-3 btn-hover-effect"
                onClick={() =>
                  navigate("/user/register", { state: { role: "user" } })
                }
                disabled={isLoggedIn}
              >
                Click here to register
              </button>
              <button
                className="btn btn-md btn-danger rounded-pill px-4 py-2 display-4 mr-2 mb-3 btn-hover-effect"
                onClick={() => navigate("/login", { state: { role: "user" } })}
                disabled={isLoggedIn}
              >
                Login to find the audit report
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyReportingIntro;
