import axios from "axios";
import API_URL from "../config/api";

export const vendorRegisterService = (vendor) => {
  return axios
    .post(API_URL.VENDOR_REGISTER, vendor)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Registration failed";
      return Promise.reject(errorMessage);
    });
};
export const mostInquiriedByDurationAndType = (durationInMonths, type) => {
  const apiUrl = `/analytics/most-inquiries?durationInMonths=${durationInMonths}&type=${type}`;

  return axios
    .get(apiUrl)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch analytics";
      return Promise.reject(errorMessage);
    });
};

export const mostInquiriedReceivedByDurationAndType = (
  durationInMonths,
  type
) => {
  const apiUrl = `/analytics/most-inquiries-received?durationInMonths=${durationInMonths}&type=${type}`;

  return axios
    .get(apiUrl)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch analytics";
      return Promise.reject(errorMessage);
    });
};

export const buyersRegisteredStats = (durationInMonths) => {
  const apiUrl = `/analytics/users-registered?durationInMonths=${durationInMonths}`;

  return axios
    .get(apiUrl)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch analytics";
      return Promise.reject(errorMessage);
    });
};

export const vendorsRegisteredStats = (durationInMonths) => {
  const apiUrl = `/analytics/vendors-registered?durationInMonths=${durationInMonths}`;

  return axios
    .get(apiUrl)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch analytics";
      return Promise.reject(errorMessage);
    });
};

export const vendorslist = () => {
  return axios
    .get(API_URL.GET_ALL_VENDORS)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch all vendors";
      return Promise.reject(errorMessage);
    });
};

export const userslist = () => {
  return axios
    .get(API_URL.GET_ALL_USERS)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch all users";
      return Promise.reject(errorMessage);
    });
};

export const mostactivevendors = (duration) => {
  const apiurl = `/analytics/most-active-vendors?durationInMonths=${duration}`;
  return axios
    .get(apiurl)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch most active vendors";
      return Promise.reject(errorMessage);
    });
};

export const mostactiveusers = (duration, type) => {
  const apiurl = `/analytics/most-active-users?durationInMonths=${duration}&type=${type}`;
  return axios
    .get(apiurl)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch most active users";
      return Promise.reject(errorMessage);
    });
};

export const mostactiveproductssearched = (duration, type) => {
  const apiurl = `/analytics/most-active-products?durationInMonths=${duration}&type=${type}`;
  return axios
    .get(apiurl)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch all users";
      return Promise.reject(errorMessage);
    });
};

export const inquiriesMadeDatastats = () => {
  return axios
    .get(API_URL.USER_INQUIRY_DETAILS)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch user details inquiry";
      return Promise.reject(errorMessage);
    });
};


export const countofinquiriesMadeDatastats = (duration) => {
  return axios
    .get(API_URL.NUMBER_OF_INQUIRIES_MADE+'?durationInMonths='+duration)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch count stats of inquiry";
      return Promise.reject(errorMessage);
    });
};

export const countofReportsUploaded = (months) => {
  return axios
    .get(API_URL.NUMBER_OF_REPORTS_UPLOADED+'?durationInMonths='+months)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch count stats of reports uploaded";
      return Promise.reject(errorMessage);
    });
};

export const inquiriesReceived= (months) => {
  return axios
    .get(API_URL.NUMBER_OF_INQUIRIES_RECEIVED+'?durationInMonths='+months)
    .then((res) => res.data)
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Failed to fetch count stats of inquiries received";
      return Promise.reject(errorMessage);
    });
};

