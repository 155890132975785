import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { verifyemail } from "../../../services/auth";
import { setAttributeInLocalStorageData } from "../../../utils";

function VerifyEmail() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract token from query parameter
    const token = new URLSearchParams(location.search).get("token");

    if (token) {
      verifyemail(token)
        .then((res) => {
          if (res.status === true) {
            toast.success(res.message);
            setAttributeInLocalStorageData("emailVerified", true);
          } else {
            toast.error(res.message);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      toast.error("Email verification failed");
    }
    navigate("/profile");
  }, [location.search]);

  return (
    <>
      <section
        id="registration"
        className="background-radial-gradient overflow-hidden"
      >
        <div>VerifyEmail</div>
      </section>
    </>
  );
}

export default VerifyEmail;
