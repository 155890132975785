import React, { useState, useEffect } from "react";
import { fetchallplans, purchasePlan } from "../../services/plan";
import { toast } from "react-toastify";
import { getAttributeInLocalStorageData } from "../../utils";

function PublishedPlans() {
  const itemsPerPage = 5; // Number of items to display per page

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // axios call
    fetchallplans()
      .then((data) => {
        setTableData(data); // Update the state with fetched data
      })
      .catch((error) => {
        toast.error("Error fetching plans:", error);
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total number of pages
  const totalPages = Math.ceil(tableData.length / itemsPerPage);

  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePackageBuy = (planid) => {
    purchasePlan(planid)
      .then(() => {
        toast.success("Plan Purchase Successfully");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <div className="card">
        <div id="registeredvendorstable" className="card-body p-3 0 my-3 mx-2">
          <h5 className="card-title">Details of Plans Published</h5>
          <h1>
            {/* abcd {currentItems} - {tableData} - {publishedAuditReports.length} */}
          </h1>

          {tableData.length !== 0 ? (
            <table
              id="table"
              className="table table-responsive table-hover caption-top box-shadow-border-radius"
            >
              <thead>
                <tr>
                  <th scope="col" className="col-1 text-center">
                    #
                  </th>
                  <th scope="col" className="col-2 text-center">
                    Plan Name
                  </th>
                  <th scope="col" className="col-2 text-center">
                    Credits
                  </th>
                  <th scope="col" className="col-2 text-center">
                    Duration In Days
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Price
                  </th>
                  <th scope="col" className="col-1 text-center">
                    Currency
                  </th>
                  {getAttributeInLocalStorageData("role") === "vendor" && (
                    <th scope="col" className="col-1 text-center">
                      Buy
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row" className="col-1 text-center">
                      {item.id}
                    </th>
                    <td className="col-2 text-center">{item.name}</td>
                    <td className="col-2 text-center">
                      {item?.credits || "default"}
                    </td>
                    <td className="col-2 text-center">{item.duration}</td>
                    <td className="col-2 text-center">{item.price}</td>
                    <td className="col-1 text-center">{item.currency}</td>
                    {getAttributeInLocalStorageData("role") === "vendor" && (
                      <td className="col-1 text-center">
                        <button
                          onClick={() => handlePackageBuy(item.id)}
                          className="btn btn-danger"
                        >
                          Buy
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1>No Reports Published so far</h1>
          )}

        </div>
        <nav
          aria-label="Page navigation"
          style={{
            marginTop: "auto", // Push the pagination to the bottom
          }}
        >
          <ul className="pagination justify-content-center">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNum) => (
                <li
                  key={pageNum}
                  className={`page-item ${pageNum === currentPage ? "active" : ""
                    }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(pageNum)}
                  >
                    {pageNum}
                  </button>
                </li>
              )
            )}
            <li
              className={`page-item ${currentPage === totalPages ? "disabled" : ""
                }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default PublishedPlans;
