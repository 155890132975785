import React from "react";
import { Link } from "react-router-dom";

function AboutBullets({ iconClass, title, description, link }) {
  return (
    <div  data-aos="zoom-in" data-aos-delay="200">
      {/* <div className="icon">
        <i className={`bx ${iconClass}`}></i>
      </div> */}
      {/* <h4 className="title">
        <a href={link}>{title}</a>
      </h4> */}
      <p className="description">
      <Link to={"/"}>GmpAuditReports.com </Link>
      {description}</p>
    </div>
  );
}

export default AboutBullets;
