import React, { useState } from "react";
import { toast } from "react-toastify";
import { adminRegisterService } from "../../../services/auth";
import countries from "../../../data/Countries";

function AddNewAdmin() {
  const [showDialog, setShowDialog] = useState(false);

  const initialFormData = {
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    company: "",
    name: "",
    jobRole: "",
    contact: "",
    country: "",
    recaptchaString: "asdfgdfghjm",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setShowDialog(true);
    adminRegisterService(formData)
      .then(() => {
        toast.success("Admin Registered Successfully");
        setFormData(initialFormData);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      // Find the selected country based on the value (country code)
      const selectedCountry = countries.find(
        (country) => country.code === value
      );
      setFormData({
        ...formData,
        country: selectedCountry ? selectedCountry.name : "",
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <div className="card">
      <div className="card-body p-3 0 my-3 mx-2">
        <h5 className="card-title">Add New Admin</h5>
        <form className="" onSubmit={handleFormSubmit}>
          <div className="row mb-3">
            <label htmlFor="name" className="col-md-4 col-lg-3 col-form-label">
              User Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="username"
                type="text"
                className="form-control"
                id="username"
                required
                minLength={3}
                value={formData.username}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="password"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Password
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="password"
                type="password"
                className="form-control"
                id="password"
                required
                minLength={9}
                maxLength={20}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$"
                title="Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 9 characters long."
                value={formData.password}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="confirmPassword"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Confirm Password
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="confirmPassword"
                type="password"
                className="form-control"
                id="confirmPassword"
                required
                pattern={`^${formData.password}$`}
                title="Passwords must match."
                value={formData.confirmPassword}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="email" className="col-md-4 col-lg-3 col-form-label">
              Email
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="email"
                type="email"
                className="form-control"
                id="number"
                required
                value={formData.email}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="company"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Company
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="company"
                type="text"
                className="form-control"
                id="company"
                required
                value={formData.company}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="name" className="col-md-4 col-lg-3 col-form-label">
              Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="name"
                type="text"
                className="form-control"
                id="name"
                required
                value={formData.name}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="jobRole"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Job Role
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="jobRole"
                type="text"
                className="form-control"
                id="jobRole"
                required
                value={formData.jobRole}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="contactattribute"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Contact
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="contactattribute"
                type="text"
                className="form-control"
                id="contactattribute"
                required
                value={formData.contact}
                minLength="8" // Minimum length to account for international format
                maxLength="15" // Maximum length for longer contact numbers
                pattern="^[+]?[0-9]+$" // Pattern to match numbers and optional plus sign
                title="Enter a valid contact number"
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="country"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Country
            </label>
            <div className="col-md-8 col-lg-9">
              <select
                id="country"
                name="country"
                className="form-select" // Use form-select class for dropdowns
                required
                defaultValue=""
                onChange={handleChange}
              >
                <option value="" disabled></option>
                {/* Map over the countries list and create options for each country */}
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-danger">
              Add Admin
            </button>
          </div>
        </form>
      </div>
      {/* {showDialog && (
        <DialogPublishYourAuditReport
          handleCloseDialog={handleCloseDialog}
          formData={formData}
        />
      )} */}
    </div>
  );
}

export default AddNewAdmin;
