import React from "react";

const QAndA = (props) => {
  const { question, answer, dataAos, dataAosDelay } = props;

  return (
    <li data-aos={dataAos} data-aos-delay={dataAosDelay}>
      <i className="bx bx-help-circle icon-help"></i>{" "}
      <a
        data-bs-toggle="collapse"
        className="collapse"
        data-bs-target={`#${dataAos}`}
      >
        {question} <i className="bx bx-chevron-down icon-show"></i>
        <i className="bx bx-chevron-up icon-close"></i>
      </a>
      <div id={dataAos} className="collapse" data-bs-parent=".faq-list">
        {answer.map((item, index) => {
          if (item.type === "p") {
            return <p key={index}>{item.content}</p>;
          } else if (item.type === "step") {
            return (
              <p key={index} className="step-label">
                {item.content}
              </p>
            );
          } else if (item.type === "ul") {
            return (
              <ul key={index}>
                {item.content.map((listItem, i) => (
                  <li key={i}>{listItem}</li>
                ))}
              </ul>
            );
          } else {
            return <p key={index}>{item}</p>;
          }
        })}
      </div>
    </li>
  );
};

export default QAndA;
