import React, { useState } from "react";
import { toast } from "react-toastify";
import { planCreationService } from "../../services/plan";

function PublishNewPlan() {
  const [showDialog, setShowDialog] = useState(false);

  const initialFormData = {
    name: "",
    credits: 0,
    duration: 0,
    price: "",
    currency: "INR",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setShowDialog(true);
    planCreationService(formData)
      .then(() => {
        toast.success("Plan Published Successfully");
        setFormData(initialFormData);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <div className="card">
      <div className="card-body p-3 0 my-3 mx-2">
        <h5 className="card-title">Publish New Plan</h5>
        <form className="" onSubmit={handleFormSubmit}>
          <div className="row mb-3">
            <label htmlFor="name" className="col-md-4 col-lg-3 col-form-label">
              Plan Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="name"
                type="text"
                className="form-control"
                id="name"
                required
                value={formData.name}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="credits"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Credits
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="credits"
                type="number"
                className="form-control"
                id="credits"
                required
                value={formData.credits}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="duration"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Duration In Days
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="duration"
                type="number"
                className="form-control"
                id="duration"
                required
                value={formData.duration}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label htmlFor="price" className="col-md-4 col-lg-3 col-form-label">
              Price
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="price"
                type="number"
                className="form-control"
                id="number"
                required
                value={formData.price}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="currency"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Currency
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="currency"
                type="text"
                className="form-control"
                id="currency"
                required
                value={formData.currency}
              />
            </div>
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-danger">
              Publish Plan
            </button>
          </div>
        </form>
      </div>
      {/* {showDialog && (
        <DialogPublishYourAuditReport
          handleCloseDialog={handleCloseDialog}
          formData={formData}
        />
      )} */}
    </div>
  );
}

export default PublishNewPlan;
