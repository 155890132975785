import React from "react";

const PackageOption = (props) => {
  const { title, europrice, inrprice, bullets, className } = props;

  return (
    <div className="col-lg-4 col-md-6">
      <div className={className} data-aos="zoom-in" data-aos-delay="100">
        <h3>{title}</h3>
        <h4>
          <sup>€</sup>
          {europrice} / <sup>₹</sup>
          {inrprice}
          <span> / month</span>
        </h4>
        <ul>
          {bullets.map((bullet, index) => (
            <li key={index}>{bullet}</li>
          ))}
        </ul>
        <div className="btn-wrap">
          <a className="btn-buy">Buy Now</a>
        </div>
      </div>
    </div>
  );
};

export default PackageOption;
