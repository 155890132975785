import React from "react";
import HeroSection from "../homepage-section-components/HeroSection";
import MainSection from "../MainSection";
import Header from "../Header";

function FullPage({ showBackground }) {
  return (
    <>
      <Header showBackground={showBackground} />
      {/* Hero Section */}
      <HeroSection />

      {/* Main Section */}
      <MainSection />
    </>
  );
}

export default FullPage;
