import React, { createContext, useContext, useState } from "react";
import { isAuthenticated } from "../utils";
const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(isAuthenticated());
  const [userRole, setUserRole] = useState("");

  const value = {
    isLoggedIn,
    setIsLoggedIn,
    userRole,
    setUserRole, // Set user role function
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
