import axios from "axios";
import { getToken } from "../utils";
import config from "../config/index";

const configureAxios = () => {
  axios.defaults.headers.common["Authorization"] = getToken();

  //we can also create custom axios instance only for specific desired routes with axios.create
  axios.defaults.baseURL = config.baseURL;
  // const cancelToken = axios.CancelToken.source();

  axios.interceptors.request.use(
    (config) => {
      const time = new Date();
      console.log(
        `${config.method.toUpperCase()} request sent to ${
          config.url
        } at ${time.getTime()} - ${time.toLocaleString()}`
      );
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      const time = new Date();
      console.log(
        `RESPONSE - StatusCode:${
          response.status
        } at ${time.getTime()} - ${time.toLocaleString()} \n`
        // JSON.stringify(response)
      );
      console.dir(response);
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default configureAxios;
