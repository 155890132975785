import React from "react";
import PackageOption from "../subcomponents/PackageOption";

function MainPricingSection() {
  const packageOptions = [
    {
      title: "Plan - 1",
      europrice: 200,
      inrprice: 20000,
      bullets: ["Credits - 12", "Validity - 30 Month"],
      className: "box",
    },
    {
      title: "Plan - 2",
      europrice: 700,
      inrprice: 65000,
      bullets: ["Credits - 50", "Validity - 30 Months"],
      className: "box",
    },
    {
      title: "Plan - 3",
      europrice: 1500,
      inrprice: 150000,
      bullets: ["Credits - Unlimited", "Validity - 30 Months"],
      className: "box featured",
    },
  ];

  return (
    <>
      <section id="pricing" className="pricing">
        <div className="container">
          {/* <div className="section-title" data-aos="fade-up">
            <h2>Pricing</h2>
            <p>Check our Pricing</p>
          </div> */}

          <div className="row" data-aos="fade-left">
            {packageOptions.map((option, index) => (
              <PackageOption
                key={index}
                title={option.title}
                europrice={option.europrice}
                inrprice={option.inrprice}
                bullets={option.bullets}
                className={option.className}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default MainPricingSection;
