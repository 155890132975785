import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { getAttributeInLocalStorageData } from "../utils";
import { toast } from "react-toastify";

function ProtectedRoute({ permissions, children }) {
  const { isLoggedIn, userRole } = useAuth();

  if (isLoggedIn) {
    // Perform authorization checks here if needed
    // Check user's role against required permissions

    // For demonstration, assuming userRole should match at least one permission
    if (
      !permissions ||
      permissions.includes(getAttributeInLocalStorageData("role"))
    ) {
      // return <Route path={path} element={element} />;
      return children;
    }
  }
  toast.error("Please login with proper access");
  console.log("came from windows " + window.location);
  return <Navigate to="/login" />;
}

export default ProtectedRoute;
