import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

function PaymentFail() {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      navigate("/");
    }
  }, [timer, navigate]);

  return (
    <section className="paymentfail" id="paymentfail">
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="col-md-4">
          <div className="border border-3 border-danger"></div>
          <div className="card bg-white shadow p-5">
            <div className="mb-4 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="75"
                fill="currentColor"
                className="bi bi-x-circle text-danger"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a8 8 0 1 0 8 8A8 8 0 0 0 8 0zm3.354 4.646a.5.5 0 1 1-.708.708L8 8.707 5.354 11.354a.5.5 0 1 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 .708-.708L8 7.293l2.646-2.647a.5.5 0 0 1 .708 0z" />
              </svg>
            </div>
            <div className="text-center">
              <h1>Payment Failed</h1>
              <p>
                Oops! Something went wrong. Please try again after some time.
              </p>
              <Link to="/" className="btn btn-outline-danger">
                Go Back Home
              </Link>
              <div className="mt-3">Redirecting in {timer} seconds...</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaymentFail;
