import React, { useState } from "react";
import { toast } from "react-toastify";
import countries from "../../data/Countries";
import { itemCreationService } from "../../services/item";

function PublishYourAuditReport(props) {
  const [showDialog, setShowDialog] = useState(false);

  const initialFormData = {
    productName: "",
    manufacturerName: "",
    unitNumber: "",
    location: "",
    country: "",
    dateOfAudit: "",
    reportValidTill: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  // const [auditReport, setAuditReport] = useState(null);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Here, you can perform any necessary actions with the form data.
    // For demonstration purposes, we will just log the data.
    console.log("Form submitted:", formData);
    setShowDialog(true);
    itemCreationService(formData)
      .then(() => {
        toast.success("Item Published Successfully");
        props.setPublishedReportCount(props.publishedReportCount + 1);
        setFormData(initialFormData);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  // const handleProfileChange = (event) => {
  //   console.log("handle profile change " + event.target.files);
  //   const file = event.target.files[0];
  //   setAuditReport(file); // Update the profileImage state with the selected file
  // };

  // const handleAuditReportUploadSubmit = async (event) => {
  //   event.preventDefault();
  //   console.log("auditReport" + auditReport.name.replace(/\.[^/.]+$/, ""));
  //   audtiReportUploadService(auditReport)
  //     .then(() => {
  //       toast.success("Profile Uploaded Successfully");
  //     })
  //     .catch((error) => {
  //       toast.error(error);
  //     });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      const selectedCountry = countries.find(
        (country) => country.code === value
      );
      setFormData({
        ...formData,
        country: selectedCountry ? selectedCountry.code : "",
      });
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <div className="card">
      <div className="card-body p-3 0 my-3 mx-2">
        <h5 className="card-title">Publish Your Audit Report</h5>
        {/* <form onSubmit={handleAuditReportUploadSubmit}>
          <div className="row mb-3">
            <label
              htmlFor="auditreport"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Audit Report
            </label>
            <div className="col-md-8 col-lg-9">
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="file"
                  name="auditreport"
                  accept="image/*, application/pdf" // Accept both image and PDF
                  style={{ display: "none" }}
                  onChange={handleProfileChange}
                  id="auditreportInput"
                />
                <label
                  htmlFor="auditreportInput"
                  className="btn btn-primary btn-sm"
                  title="Upload new Audit Report"
                >
                  <i className="bi bi-upload" style={{ color: "white" }}></i>
                </label>
                {auditReport?.name ? (
                  <span
                    className="ms-2"
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {auditReport.name}
                  </span>
                ) : (
                  <span className="ms-2">No file selected</span>
                )}
              </div>
            </div>
          </div>
          <div className="text-center mb-3">
            <button type="submit" className="btn btn-primary">
              Upload Audit Report
            </button>
          </div>
        </form> */}
        <form className="" onSubmit={handleFormSubmit}>
          <div className="row mb-3">
            <label
              htmlFor="productName"
              className="col-md-4 col-lg-3 col-form-label label"
            >
              Product Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="productName"
                type="text"
                className="form-control"
                id="productName"
                required
                value={formData.productName}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="manufacturename"
              className="col-md-4 col-lg-3 col-form-label label"
            >
              Manufacturer Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="manufacturerName"
                type="text"
                className="form-control"
                id="manufacturerName"
                required
                value={formData.manufacturerName}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="unitNumber"
              className="col-md-4 col-lg-3 col-form-label label"
            >
              Unit Number
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="unitNumber"
                type="text"
                className="form-control"
                id="unitNumber"
                value={formData.unitNumber}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="location"
              className="col-md-4 col-lg-3 col-form-label label"
            >
              Location
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="location"
                type="text"
                className="form-control"
                id="location"
                required
                value={formData.location}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="country"
              className="col-md-4 col-lg-3 col-form-label label"
            >
              Country
            </label>
            <div className="col-md-8 col-lg-9">
              <select
                id="country"
                name="country"
                className="form-control"
                required
                value={formData.country}
                onChange={handleChange}
                style={{ appearance: 'auto' }}
              >

                <option value="" disabled>
                  Select Country
                </option>
                {countries.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
              {/* <input
                onChange={handleChange}
                name="country"
                type="text"
                className="form-control"
                id="country"
                required
                value={formData.country}
              /> */}
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="dateOfAudit"
              className="col-md-4 col-lg-3 col-form-label label"
            >
              Date Of Audit
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="dateOfAudit"
                type="date"
                className="form-control"
                id="dateOfAudit"
                required
                value={formData.dateOfAudit}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label
              htmlFor="reportValidTill"
              className="col-md-4 col-lg-3 col-form-label label"
            >
              Report Valid Till
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                onChange={handleChange}
                name="reportValidTill"
                type="date"
                className="form-control"
                id="reportValidTill"
                required
                value={formData.reportValidTill}
              />
            </div>
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-danger">
              Publish Report
            </button>
          </div>
        </form>
      </div>
      {/* {showDialog && (
        <DialogPublishYourAuditReport
          handleCloseDialog={handleCloseDialog}
          formData={formData}
        />
      )} */}
    </div>
  );
}

export default PublishYourAuditReport;
