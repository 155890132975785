import axios from "axios";
import {
  getAttributeInLocalStorageData,
  setAttributeInLocalStorageData,
} from "../utils";
import API_URL from "../config/api";

export const updateProfile = (data) => {
  return axios
    .put("/" + getAttributeInLocalStorageData("role"), data)
    .then((res) => {
      console.log("original res updateProfile " + res.data);
      setAttributeInLocalStorageData("jobRole", res.data.data.jobRole);
      setAttributeInLocalStorageData("company", res.data.data.company);
      setAttributeInLocalStorageData("contact", res.data.data.contact);
      setAttributeInLocalStorageData("companyBrief", res.data.data.companyBrief);
      return "Profile Updated Successfully";
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Profile Updated Failed";
      return Promise.reject(errorMessage);
    });
};

export const contact = (data) => {
  return axios
    .post(API_URL.CONTACT_SEND,data)
    .then((res) => {
      return "Contact send successfully";
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Contact send failed";
      return Promise.reject(errorMessage);
    });
};