let API_URL = {
  STATUS: "/",
  ADMIN_LOGIN: "/admin/login",
  REGISTER_ADMIN: "/admin",
  ADMIN_ADD: "/admin/addAdmin",
  USER_LOGIN: "/user/login",
  USER_REGISTER: "/user",
  GET_USER: "/user",
  UPDATE_USER: "/user",
  DELETE_USER: "/user",
  GET_ALL_USERS: "/user/all",
  USER_INQUIRY_DETAILS: "/user/inquiry",
  GET_ALL_VENDORS: "/vendor/all",
  GET_USER_BY_ID: "/user/id/", //append id
  VENDOR_LOGIN: "/vendor/login",
  VENDOR_REGISTER: "/vendor",
  GET_VENDOR_BY_ID: "/vendor/id/", //append id
  UPDATE_VENDOR: "/vendor",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  UPDATE_PASSWORD: "/auth/update-password",
  CREATE_ITEM: "/item",
  GET_VENDOR_ITEMS: "/item",
  ITEM_SEARCH: "/item/search",
  GET_ITEM_BY_ID: "/item/id/", //append id
  GET_ALL_ITEMS: "item/all",
  CREATE_PLAN: "/plan",
  GET_ALL_PLANS: "/plan",
  GET_PLAN_BY_ID: "/plan/id/", //append id
  SEND_QUERY: "/user/inquiry",
  UPDATE_PLAN: "/plan/id/", //append id
  SEND_VERIFICATION_MAIL: "/auth/send-verification-email", //append id
  VERIFY_EMAIL: "/auth/verify-email",
  UPLOAD_URL: "/file/upload-url",
  DOWNLOAD_URL: "/file/download-url",
  UPDATE_ITEM_BY_ID: "/item/id/", //append id
  DELETE_ITEM_BY_ID: "/item/id/", //append id
  NUMBER_OF_INQUIRIES_MADE: "/analytics/inquiries",
  NUMBER_OF_REPORTS_UPLOADED: "/analytics/reports-uploaded",
  NUMBER_OF_INQUIRIES_RECEIVED: "/analytics/inquiries-received",
  BLOCK_PERSON: "/auth/block", //append id
  UNBLOCK_PERSON: "/auth/unblock", //append id
  CONTACT_SEND: "/contactUs",
  PLAN_NEW_AUDIT: "/user/plan-new-audit",
};

export default API_URL;
