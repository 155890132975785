import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexLineChart = (props) => {
  const xaxisctg = props.xaxisctg;
  const data = props.data;
  const seriesData = [
    {
      name: "High - 2013",
      data: data,
    },
  ];

  const chartOptions = {
    chart: {
      height: 350,
      type: "line",
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#77B6EA"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      // text: "Average High & Low Temperature",
      // align: "left",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: xaxisctg,
      title: {
        text: "Month",
      },
    },
    yaxis: {
      title: {
        text: "Buyers Count",
      },
      min: 1,
      max: Math.round(Math.max(...data, 10) / 10) * 10,
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={chartOptions}
        series={seriesData}
        type="line"
        height={350}
      />
    </div>
  );
};

export default ApexLineChart;
