import React, { useState } from "react";
import countries from "../../data/Countries";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { vendorRegisterService } from "../../services/auth";
import Header from "../Header";
import phonepins from "../../data/PhonePins"
function VendorRegistrationPage({ showBackground }) {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    company: "",
    companyBrief: "",
    name: "",
    jobRole: "",
    phonepin: "",
    contact: "",
    country: "",
    companyLogo: null,
    sampleAuditReport: null,
  });

  const handleChange = (event) => {
    const { name, value, files } = event.target;
    if (name === "country") {
      const selectedCountry = countries.find(
        (country) => country.code === value
      );
      setFormData({
        ...formData,
        country: selectedCountry ? selectedCountry.name : "",
      });
    } else {
      setFormData({
        ...formData,
        [name]:
          name === "companyLogo" || name === "sampleAuditReport"
            ? files[0]
            : value,
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Custom validations
    const errors = validateForm();
    
    if (Object.keys(errors).length === 0) {
      const temp = formData;
      temp.contact = temp.phonepin + temp.contact;
      // No validation errors, proceed with the registration
      vendorRegisterService(temp)
        .then(() => {
          toast.success("Registration successful!");
          navigate("/login");
        })
        .catch((error) => {
          toast.error(error.message || "Registration failed");
        });
    } else {
      // Show the validation errors
      for (let key in errors) {
        toast.error(errors[key]);
      }
    }
  };

  // Custom form validation function
const validateForm = () => {
  let errors = {};

  // Username validation
  if (formData.username.length < 3) {
    errors.username = "Username must be at least 3 characters long.";
  }

  // Password validation
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,20}$/;
  if (!passwordRegex.test(formData.password)) {
    errors.password = "Password must be between 9 and 20 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.";
  }

  // Confirm password validation
  if (formData.confirmPassword !== formData.password) {
    errors.confirmPassword = "Passwords do not match.";
  }

  // Email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(formData.email)) {
    errors.email = "Please enter a valid email address.";
  }

  // Company name validation
  if (formData.company.length === 0) {
    errors.company = "Company name is required.";
  }

  // Name validation
  if (formData.name.length === 0) {
    errors.name = "Name is required.";
  }

  // Job role validation
  if (formData.jobRole.length === 0) {
    errors.jobRole = "Job role is required.";
  }

  // Contact number validation
  const contactRegex = /^[+]?[0-9]{8,15}$/;
  if (!contactRegex.test(formData.contact)) {
    errors.contact = "Please enter a valid contact number (8-15 digits).";
  }

  // Country validation
  if (!formData.country) {
    errors.country = "Please select a country.";
  }

  //  companyBrief validation
  if (formData.companyBrief.length === 0) {
    errors.company = "companyBrief shout have atleast 3 characters.";
  }

  return errors;
};

  const navigate = useNavigate();

  return (
    <>
      <Header showBackground={showBackground} />
      <section
        id="registration"
        className="background-radial-gradient overflow-hidden"
      >
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <h1 className="my-5 display-5 fw-bold ls-tight text-light">
                Register to
                <span style={{ color: "rgb(255, 255, 255)" }}>
                  Explore GMP Audit Reports
                  </span>
              </h1>
            </div>

            <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
              <div className="card bg-light">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        required
                        // minLength={3}
                        value={formData.username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        required
                        // minLength={9}
                        // maxLength={20}
                        // pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$"
                        title="Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 9 characters long."
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        required
                        // pattern={`^${formData.password}$`}
                        title="Passwords must match."
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="company" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        required
                        value={formData.company}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="company brief" className="form-label">
                        Company Brief
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="companyBrief"
                        name="companyBrief"
                        rows="2"
                        required
                        value={formData.companyBrief}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="jobRole" className="form-label">
                        Job Role
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobRole"
                        name="jobRole"
                        required
                        value={formData.jobRole}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="contact" className="form-label">
                        Contact Number
                      </label>
                      <div className="d-flex mb-3">
                        <div className="mb-3 col-3">
                          <select
                            id="phonepin"
                            name="phonepin"
                            className="form-select"
                            required
                            defaultValue=""
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Country Code
                            </option>
                            {[...new Map(phonepins.map(item => [item.code, item])).values()]
                            .sort((a, b) => parseInt(a.code.replace('+', '')) - parseInt(b.code.replace('+', '')))
                            
                            .map((phonepin) => (
                              <option key={phonepin.code} value={phonepin.code}>
                                {phonepin.code}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="ms-1 col-9 ">
                          <input
                            type="text"
                            className="form-control"
                            id="contactnumber"
                            name="contact"
                            minLength="8"
                            maxLength="15"
                            pattern="^[+]?[0-9]+$"
                            title="Enter a valid contact number"
                            required
                            value={formData.contact}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        className="form-control"
                        required
                        defaultValue=""
                        onChange={handleChange}
                        style={{ appearance: 'auto' }}
                      >
                        <option value="" disabled>
                          Select Country
                        </option>
                        {countries.map((country) => (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="companyLogo" className="form-label">
                        Company Logo (Optional)
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="companyLogo"
                        name="companyLogo"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="sampleAuditReport" className="form-label">
                        Sample Audit Report (Optional)
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="sampleAuditReport"
                        name="sampleAuditReport"
                        accept=".pdf,.doc,.docx"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="companyBrief" className="form-label">
                        Company Brief 
                      </label>
                      <input
                        type="textbox"
                        className="form-control"
                        id="companyBrief"
                        name="companyBrief"
                        rows={3}
                        required
                        value={formData.companyBrief}
                        onChange={handleChange}
                      />
                    </div>
                    <button type="submit" className="btn btn-danger w-100">
                      Register
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VendorRegistrationPage;
