import axios from "axios";
import API_URL from "../config/api";

export const itemCreationService = (item) => {
  return axios
    .post(API_URL.CREATE_ITEM, { items: [item] })
    .then((res) => {
      console.log(res.data);
      return "Item Published Successfully";
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Item Publish failed";
      return Promise.reject(errorMessage);
    });
};
// success response actually is:
// {
//     "0": {
//         "id": 7,
//         "productName": "product792",
//         "manufacturerName": "manufacturer792"
//     },
//     "status": true,
//     "message": "1 Item(s) created successfully"
// }

export const fetchallmyitems = () => {
  return axios
    .get(API_URL.GET_VENDOR_ITEMS)
    .then((res) => {
      console.log("Items fetched successfully", res);
      return res.data.data;
    })
    .catch((err) => {
      const errorMessage = err?.response?.data?.message || "Items fetch failed";
      return Promise.reject(errorMessage);
    });
};

export const fetchallitems = () => {
  return axios
    .get(API_URL.GET_ALL_ITEMS)
    .then((res) => {
      console.log("Items fetched successfully", res);
      return res.data.data;
    })
    .catch((err) => {
      const errorMessage = err?.response?.data?.message || "Items fetch failed";
      return Promise.reject(errorMessage);
    });
};

export const searchItems = (data) => {
  console.log("search items " + JSON.stringify(data))
  return axios
    .post(API_URL.ITEM_SEARCH, {
      searchQuery: {
        productName: data.productname,
        manufacturerName: data.manufacturename,
        country: data.country,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage = err?.response?.data?.message || "Item Search failed";
      return Promise.reject(errorMessage);
    });
};

export const updateItem = (data, itemId) => {
  return axios
    .put(API_URL.UPDATE_ITEM_BY_ID + itemId, { data })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Report Update Failed";
      return Promise.reject(errorMessage);
    });
};

export const deleteItemById = (itemId) => {
  return axios
    .delete(API_URL.DELETE_ITEM_BY_ID + itemId)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage =
        err?.response?.data?.message || "Report Deletion Failed";
      return Promise.reject(errorMessage);
    });
};
