import React, { useState } from "react";
import { forgotpassword } from "../../services/auth";
import { toast } from "react-toastify";
import Header from "../Header";

function ForgotPassword({ showBackground }) {
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    forgotpassword(formData)
      .then((res) => {
        if (res.status === true) {
          toast.success("Reset link is sent to your Email");
        } else {
          toast.error(
            "Failed to send reset link. Please try again after some time"
          );
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      {/* Section: Design Block */}
      <Header showBackground={showBackground} />
      <section
        id="forgot-password"
        className="background-radial-gradient overflow-hidden"
      >
        <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
          <div className="row gx-lg-5 align-items-center mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0 position-relative">
              <div
                id="radius-shape-1"
                className="position-absolute rounded-circle shadow-5-strong"
              ></div>
              <div
                id="radius-shape-2"
                className="position-absolute shadow-5-strong"
              ></div>

              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={handleSubmit}>
                    {/* <!-- Email input --> */}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        required
                        id="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    {/* <!-- Submit button --> */}
                    <button
                      type="submit"
                      className="btn btn-danger btn-block mb-4"
                    >
                      Reset Password
                    </button>
                    {/* <!-- Register buttons --> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section: Design Block */}
    </>
  );
}

export default ForgotPassword;
