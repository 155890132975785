import React, { useState } from "react";
import countries from "../../data/Countries";
import phonepins from "../../data/PhonePins"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { userRegisterService } from "../../services/auth";
import Header from "../Header";
function UserRegistrationPage({ showBackground }) {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    email: "",
    company: "",
    name: "",
    jobRole: "",
    contact: "",
    phonepin: "",
    country: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "country") {
      const selectedCountry = countries.find(
        (country) => country.code === value
      );
      setFormData({
        ...formData,
        country: selectedCountry ? selectedCountry.name : "",
      });
    }else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const temp = formData;
    temp.contact = temp.phonepin + temp.contact;
    userRegisterService(temp)
      .then(() => {
        toast.success("Registration successful! Redirecting to login...");
        navigate("/login");
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const navigate = useNavigate();

  return (
    <>
      <Header showBackground={showBackground} />
      <section
        id="registration"
        className="background-radial-gradient overflow-hidden"
      >
        <div className="container py-5">
          <div className="row gx-lg-5 align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="text-center text-lg-start">
                <h1 className="my-5 display-5 fw-bold ls-tight text-light">
                  Register To <br />
                  <span style={{ color: "rgb(255, 255, 255)" }}>
                  Explore GMP Audit Reports
                  </span>
                </h1>
              </div>
            </div>
            <div className="col-lg-6 position-relative">
              <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        required
                        minLength={3}
                        value={formData.username}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        required
                        minLength={9}
                        maxLength={20}
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{9,}$"
                        title="Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 9 characters long."
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="confirmPassword" className="form-label">
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        required
                        pattern={`^${formData.password}$`}
                        title="Passwords must match."
                        value={formData.confirmPassword}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="company" className="form-label">
                        Company Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        required
                        value={formData.company}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="jobRole" className="form-label">
                        Job Role
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="jobRole"
                        name="jobRole"
                        required
                        value={formData.jobRole}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="contact" className="form-label">
                        Contact Number
                      </label>
                      <div className="d-flex mb-3">
                        <div className="mb-3 col-3">
                          <select
                            id="phonepin"
                            name="phonepin"
                            className="form-select"
                            required
                            defaultValue=""
                            onChange={handleChange}
                          >
                            <option value="" disabled>
                              Country Code
                            </option>
                            {[...new Map(phonepins.map(item => [item.code, item])).values()]
                            .sort((a, b) => parseInt(a.code.replace('+', '')) - parseInt(b.code.replace('+', '')))
                            
                            .map((phonepin) => (
                              <option key={phonepin.code} value={phonepin.code}>
                                {phonepin.code}
                              </option>
                            ))}

                          </select>
                        </div>
                        <div className="ms-1 col-9 ">
                          <input
                            type="text"
                            className="form-control"
                            id="contactnumber"
                            name="contact"
                            minLength="8"
                            maxLength="15"
                            pattern="^[+]?[0-9]+$"
                            title="Enter a valid contact number"
                            required
                            value={formData.contact}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="mb-3">
                      <label htmlFor="country" className="form-label">
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        className="form-select"
                        required
                        defaultValue=""
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Country
                        </option>
                        {countries.map((country) => (
                          <option key={country.code} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-danger btn-lg rounded-pill"
                      >
                        Register
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UserRegistrationPage;
