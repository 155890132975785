import React from "react";
import TeamMember from "../subcomponents/TeamMember";
function MainTeamSection() {
  const teamMembers = [
    {
      imageUrl: "assets/img/team/srinivas-2.jpg",
      name: "Srinivasa Rao",
      designation: "Chief Executive Officer",
      socialLinks: [
        "https://twitter.com/",
        "https://www.facebook.com/",
        "https://www.instagram.com/",
        "https://www.linkedin.com/",
      ],
    },

    {
      imageUrl: "assets/img/team/team-3.jpg",
      name: "William Anderson",
      designation: "CTO",
      socialLinks: [
        "https://twitter.com/",
        "https://www.facebook.com/",
        "https://www.instagram.com/",
        "https://www.linkedin.com/",
      ],
    },
  ];

  return (
    <>
      <section id="team" className="team">
        <div className="container">
          <div className="section-title" data-aos="fade-up">
            <h2>Team</h2>
            <p>Our Great Team</p>
          </div>

          <div className="row" data-aos="fade-left">
            {teamMembers.map((member, index) => (
              <TeamMember
                key={index}
                imageUrl={member.imageUrl}
                name={member.name}
                designation={member.designation}
                socialLinks={member.socialLinks}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default MainTeamSection;
