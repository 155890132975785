import axios from "axios";
import API_URL from "../config/api";
import { getAttributeInLocalStorageData } from "../utils";

export const downloadCurrentUserFileService = async (file) => {
  const options = {
    method: "POST",
    url: API_URL.DOWNLOAD_URL,
    headers: {
      "Content-Type": "application/json",
    },
    data: { file: file, personId: getAttributeInLocalStorageData("id") },
  };
  return axios
    .request(options)
    .then((res) => {
      console.log(
        " res success downloadCurrentUserFileService " + res.data.data
      );
      return res.data;
    })
    .catch((err) => {
      const errorMessage = err?.response?.data?.message || "File not found";
      return Promise.reject(errorMessage);
    });
};

export const downloadFileService = async (file, personId) => {
  const options = {
    method: "POST",
    url: API_URL.DOWNLOAD_URL,
    headers: {
      "Content-Type": "application/json",
    },
    data: { file: file, personId: personId },
  };
  return axios
    .request(options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      const errorMessage = err?.response?.data?.message || "File not found";
      return Promise.reject(errorMessage);
    });
};

// UPLOAD_URL : /file/upload-url

export const profileUploadService = async (profileImage) => {
  return new Promise(async (resolve, reject) => {
    try {
      const requestBody = { image: profileImage.name };
      //.name.replace(/\.[^/.]+$/, "")

      const options = {
        method: "POST",
        url: API_URL.UPLOAD_URL, // Update with the actual URL
        headers: {
          "Content-Type": "application/json",
        },
        data: requestBody,
      };

      const uploadUrlResponse = await axios.request(options);
      console.log("uploadUrlResponse response: ", uploadUrlResponse);

      if (uploadUrlResponse.data.status) {
        const uploadUrl = uploadUrlResponse.data.data;

        const headers = {
          Authorization: undefined,
        };

        const uploadResponse = await axios.put(uploadUrl, profileImage, {
          headers,
        });
        console.log("upload response: ", uploadResponse);

        resolve("Profile Image Uploaded Successfully");
      } else {
        console.log("Error getting upload URL");
        reject("Error getting upload URL");
      }
    } catch (error) {
      console.error("Error uploading profile image:", error);
      reject(error);
    }
  });
};

export const audtiReportUploadService = async (profileImage) => {
  return new Promise(async (resolve, reject) => {
    try {
      const requestBody = {
        report: profileImage.name,
      };

      const options = {
        method: "POST",
        url: API_URL.UPLOAD_URL, // Update with the actual URL
        headers: {
          "Content-Type": "application/json",
        },
        data: requestBody,
      };

      const uploadUrlResponse = await axios.request(options);
      console.log("uploadUrlResponse response: ", uploadUrlResponse);

      if (uploadUrlResponse.data.status) {
        const uploadUrl = uploadUrlResponse.data.data;

        const headers = {
          Authorization: undefined,
        };

        const uploadResponse = await axios.put(uploadUrl, profileImage, {
          headers,
        });
        console.log("upload response: ", uploadResponse);

        resolve("AUdit Report Uploaded Successfully");
      } else {
        console.log("Error getting upload URL");
        reject("Error getting upload URL");
      }
    } catch (error) {
      console.error("Error uploading Audit Report:", error);
      reject(error);
    }
  });
};
