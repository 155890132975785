import React from "react";
import PriceBanner from "../subcomponents/PriceBanner";
import MainPricingSection from "../homepage-section-components/MainPricingSection";

function PricingPage() {
  return (
    <>
      <PriceBanner />
      <MainPricingSection />
    </>
  );
}

export default PricingPage;
