import React, { useState, useEffect } from "react";

function HeroSection() {
  const [activeSlide, setActiveSlide] = useState(0);

  const slideContents = [
    {
      video: "assets/video/Home page 1.mp4",
      text: "Access the unique platform to purchase or sell GMP audit reports",
    },
    {
      video: "assets/video/Home page 2.mp4",
      text: "Find the best audit service providers with one click",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % slideContents.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section id="hero" style={{ position: "relative", overflow: "hidden" }}>
      <video
            autoPlay
            muted
            loop
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          >
            <source src={"assets/video/Home page 2.mp4"} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
      {slideContents.map((content, index) => (
        <div
          key={index}
          style={{
            transition: "opacity 1s ease",
            opacity: activeSlide === index ? 1 : 0,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            minHeight: "90vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            fontSizeAdjust: "72px",
          }}
        >
          
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "7%",
              right: "7%",
              color: "white",
            }}
          >
            <h1>{content.text}</h1>
          </div>
        </div>
      ))}
    </section>
  );
}

export default HeroSection;
