import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function PaymentSuccessful() {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(5);

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  useEffect(() => {
    if (timer === 0) {
      navigate("/profile");
    }
  }, [timer, navigate]);

  const handleBackHome = () => {
    navigate("/");
  };

  return (
    <section className="paymentsuccess" id="paymentsuccess">
      <div className="vh-100 d-flex justify-content-center align-items-center">
        <div className="col-md-4">
          <div className="border border-3 border-success"></div>
          <div className="card  bg-white shadow p-5">
            <div className="mb-4 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="75"
                height="75"
                fill="currentColor"
                className="bi bi-check-circle text-success"
                viewBox="0 0 16 16"
              >
                {/* ... SVG path data ... */}
              </svg>
            </div>
            <div className="text-center">
              <h1>Thank You!</h1>
              <p>PDF is sent to your email</p>
              <button
                className="btn btn-outline-danger"
                onClick={handleBackHome}
              >
                Back Home
              </button>
              <div className="mt-3">Redirecting in {timer} seconds...</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PaymentSuccessful;
