function formatDate(dateString) {
  const date = new Date(dateString);
  let month = "" + (date.getMonth() + 1); // getMonth() returns a zero-based index
  let day = "" + date.getDate();
  const year = date.getFullYear();

  // If month or day is single digit, add a leading zero
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export default formatDate;
